<template>
    <div class="modal" v-if="modelValue" :class="{ active : modelValue }">
        <div class="modal_container">
            <div class="modal_header border-bottom">
                <h1 class="flex-col">
                    Coach Details
                    <div class="storage">
                        <span :style="`width: ${ (organizationUsers.length * 100) / membership.team_members_limit }%;`"></span>
                        <h4>You are using {{ organizationUsers.length }} of {{ membership.team_members_limit ? membership.team_members_limit : 0 }} allowed coaches.</h4>
                    </div>
                </h1>
                <button class="close_btn left_out" @click="closeModal()"><i class="fas fa-long-arrow-alt-right"></i></button>
            </div>
            <div class="modal_body height_auto">
                <div class="loader_area2" v-show="organizationUserLoader"><quote-loader/></div>
                <div v-show="!organizationUserLoader">
                    <div class="section_wpr">
                        <h3><button type="button" class="add_btn ml-auto mr-0" @click="handleAddUser()"><i class="fas fa-plus"></i>Add Coach</button></h3>
                    </div>
                    <div class="mt-2">
                        <div class="setting_wpr" v-click-outside="closeUserDropdown">
                            <div class="user_setting" v-for="(user, u) of organizationUsers" :key="u">
                                <div class="user_info">
                                    <img :src="user.profile_pic">
                                    <div class="info m-0">
                                        <h4>{{ user.full_name }}</h4>
                                        <p>{{ user.email }}</p>
                                    </div>
                                </div>
                                <div class="dropdown drop_selector" :class="{ active: selectedUserDropdown == user.id }" @click="toggleUserDropdown(user.id)">
                                    <i class="fas fa-user-cog"></i>
                                    <div class="drp_wrapper">
                                        <ul>
                                            <li @click="editUser(user)">Edit</li>
                                            <li @click="accessAccount(user.id)">Access Account</li>
                                            <li @click="handleResendLoginCredentials(user)">Resend Login</li>
                                            <li @click="handleDeleteUser(user)">Delete</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Form @keydown.enter="$event.preventDefault()" @submit="handleSubmit" v-slot="{ errors }" class="setting-form" ref="user-form">
                <!-- New user Modal -->
                <div v-show="addNewUser" class="details_wpr">
                    <div class="cardItem_details">
                        <div class="header">
                            <button class="close_btn" type="button" @click="addNewUser = false; permission = false; tab = 1;"><i class="fas fa-chevron-down"></i></button>
                            <h2>{{ form.id ? 'Edit Coach' : 'Add Coach' }}</h2>
                            <ul class="tab_sec mt-4 mb-2">
                                <li @click="tab = 1; permission = false;" :class="{ active: tab === 1 }">Coach Details</li>
                                <li @click="tab = 2; permission = true;" :class="{ active: tab === 2 }">Permissions</li>
                                <li v-if="form.id" @click="tab = 3; permission = true;" :class="{ active: tab === 3 }">Twilio</li>
                            </ul>
                        </div>
                        <div class="tab_content" v-show="tab == 1">
                            <div class="setting_wpr pb-4">
                                <div class="form_grp">
                                    <div class="group_item">
                                        <label class="input_label">Coach Type</label>
                                        <div class="field_wpr" :class="{ 'has-error': errors.user_type }">
                                            <Field autocomplete="off" name="user_type" v-model="form.user_type" :class="{ 'has-error': errors.user_type }" rules="required" label="user type">
                                                <multiselect
                                                    v-model="form.user_type"
                                                    :options="types"
                                                    value-prop="value"
                                                    label="title"
                                                    :searchable="false"
                                                    placeholder="Select coach type"
                                                    autocomplete="nofill"
                                                    ref="user-type-ms"
                                                ></multiselect>
                                            </Field>
                                        </div>
                                        <ErrorMessage name="user_type" class="text-danger" />
                                    </div>
                                </div>
                                <div class="form_grp" v-if="!form.id">
                                    <div class="group_item">
                                        <label class="input_label">Search Contact</label>
                                        <div class="field_wpr" :class="{ 'has-error': errors.contact }">
                                            <Field autocomplete="off" name="contact" v-model="form.contact_id" :class="{ 'has-error': errors.contact }" rules="required">
                                                <multiselect
                                                    v-model="form.contact_id"
                                                    :options="contacts"
                                                    value-prop="id"
                                                    label="name"
                                                    :searchable="true"
                                                    placeholder="Select contact"
                                                    :internal-search="false"
                                                    @search-change="searchContact"
                                                    autocomplete="nofill"
                                                    trackBy="search_key"
                                                    ref="contact-ms"
                                                >
                                                    <template v-slot:singlelabel="{ value }">
                                                        <div class="multiselect-single-label">
                                                        <img class="character-label-icon" :src="value.photo"> {{ value.name }}
                                                        </div>
                                                    </template>

                                                    <template v-slot:option="{ option }">
                                                        <div class="multiselect-avatar">
                                                            <img class="character-option-icon" :src="option.photo">
                                                        </div>
                                                        <div class="multiselect-title-content">
                                                            <div class="multiselect-title">{{ option.name }}</div>
                                                            <div class="multiselect-sub-title">{{ option.email }}</div>
                                                        </div>
                                                    </template>
                                                </multiselect>
                                            </Field>
                                        </div>
                                        <ErrorMessage name="contact" class="text-danger" />
                                    </div>
                                </div>
                                <Field autocomplete="off" v-if="addNewUser" type="hidden" name="step" value="1" />

                                <!-- Detaiils section -->
                                <div v-show="showDetails">
                                    <div class="form_grp col-3">
                                        <div class="group_item">
                                            <label class="input_label">Full Name</label>
                                            <div class="field_wpr" :class="{ 'has-error': errors.full_name }">
                                                <Field autocomplete="nofill" type="text" name="full_name" v-model="form.full_name" placeholder="Robert Bacins" rules="required" label="full name" />
                                            </div>
                                            <ErrorMessage name="full_name" class="text-danger" />
                                        </div>
                                    </div>
                                    <div class="form_grp">
                                        <div class="group_item">
                                            <label class="input_label">E-mail</label>
                                            <div class="field_wpr has_prefix" :class="{ 'has-error': errors.email }">
                                                <Field autocomplete="nofill" type="email" name="email" v-model="form.email" placeholder="john.doe@onboardme.io" rules="required|email" />
                                                <span class="prefix"><i class="fas fa-envelope"></i></span>
                                            </div>
                                            <ErrorMessage name="email" class="text-danger" />
                                        </div>
                                    </div>
                                    <div class="form_grp">
                                        <div class="group_item">
                                            <label class="input_label">Mobile phone</label>
                                            <div class="field_wpr" :class="{ 'has-error': errors.companyno }">
                                                <Field autocomplete="nofill" type="text" name="companyno" v-model="form.companyno" placeholder="(814) 555-1212" />
                                            </div>
                                            <ErrorMessage name="companyno" class="text-danger" />
                                        </div>
                                    </div>
                                    <div class="form_grp">
                                        <div class="group_item">
                                            <label class="input_label">Country</label>
                                            <div class="field_wpr" :class="{ 'has-error': errors.country }">
                                                <Field autocomplete="off" name="country" v-model="form.country" :class="{ 'has-error': errors.country }">
                                                    <multiselect
                                                        v-model="form.country"
                                                        :options="countries"
                                                        value-prop="code"
                                                        label="country"
                                                        :searchable="true"
                                                        placeholder="Select country"
                                                        autocomplete="nofill"
                                                    ></multiselect>
                                                </Field>
                                            </div>
                                            <ErrorMessage name="country" class="text-danger" />
                                        </div>
                                        <div class="group_item">
                                            <label class="input_label">City</label>
                                            <div class="field_wpr" :class="{ 'has-error': errors.city }">
                                                <Field autocomplete="nofill" name="city" v-model="form.city" type="text" placeholder="Enter City Here.." />
                                            </div>
                                            <ErrorMessage name="city" class="text-danger" />
                                        </div>
                                    </div>
                                    <div class="form_grp">
                                        <div class="group_item">
                                            <label class="input_label">State</label>
                                            <div class="field_wpr" :class="{ 'has-error': errors.state }">
                                                <Field autocomplete="nofill" name="state" type="text" v-model="form.state" placeholder="Enter State Here.." />
                                            </div>
                                            <ErrorMessage name="state" class="text-danger" />
                                        </div>
                                        <div class="group_item">
                                            <label class="input_label">Zip Code</label>
                                            <div class="field_wpr" :class="{ 'has-error': errors.zipcode }">
                                                <Field autocomplete="nofill" name="zipcode" type="text" v-model="form.zipcode" placeholder="Enter Zip Code Here.." />
                                            </div>
                                            <ErrorMessage name="zipcode" class="text-danger" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="action_wpr fixed_footer">
                                <a class="more_btn" v-if="form.contact_id" @click="showDetails = !showDetails"><i :class="!showDetails ?'fas fa-plus-circle' : 'fas fa-minus-circle'"></i> {{ !showDetails ? 'Show' : 'Hide' }} Details</a>
                                <button type="button" class="btn cancel_btn" @click="addNewUser = false">Cancel</button>
                                <button class="btn save_btn">Next</button>
                            </div>
                        </div>
                        <div class="tab_content" v-show="tab == 2">
                            <div class="locations-list">
                                <h4 class="permission-title">Select sub-accounts they can access</h4>
                                <div class="capsule_elm py-1">
                                    <h5>Primary Account</h5>
                                    <label for="primary-location-access" class="switch_option capsule_btn">
                                        <input type="checkbox" id="primary-location-access" v-model="form.has_company_access" :true-value="1" :false-value="0" hidden>
                                        <div><span></span></div>
                                    </label>
                                </div>
                                <div class="capsule_elm py-1" v-for="(location, l) of organizationLocations" :key="l">
                                    <h5>{{ location.company_name }}</h5>
                                    <label :for="`secondary-location-${location.id}-access`" class="switch_option capsule_btn">
                                        <input type="checkbox" :id="`secondary-location-${location.id}-access`" v-model="form.locations" :value="location.id" :true-value="location.id" :false-value="0" hidden>
                                        <div><span></span></div>
                                    </label>
                                </div>
                            </div>
                            <div class="permission_list">
                                <h4 class="permission-title">Select permissions they have with those sub-accounts</h4>
                                <template v-for="(permission, p) of permissions" :key="p">
                                    <h5>{{ permission.name }}</h5>
                                    <ul class="mb-3">
                                        <li v-for="(type, t) of permission.types" :key="t">
                                            <label :for="`permission-${permission.id}-${type.id}`" class="checkbox">
                                                <input type="checkbox" :id="`permission-${permission.id}-${type.id}`" v-model="userPermission[permission.id][type.id]" hidden>
                                                <span><i class="fas fa-check"></i></span>
                                                <p>{{ type.title }}</p>
                                            </label>
                                        </li>
                                    </ul>
                                </template>
                            </div>
                            <Field autocomplete="off" v-if="permission" type="hidden" name="step" value="2" />
                            <div class="action_wpr fixed_footer">
                                <button :disabled="userSaveLoader" type="button" class="btn cancel_btn" @click="permission = false">Cancel</button>
                                <button :disabled="userSaveLoader" class="btn save_btn"><i class="fa fa-spin fa-spinner" v-if="userSaveLoader"></i> {{ userSaveLoader ? 'Saving' : 'Save' }}</button>
                            </div>
                        </div>
                        <div class="tab_content" v-show="tab == 3">
                            <Form @submit="handleTwilioFormSubmit" v-slot="{ errors }">
                                <div class="setting_wpr pb-4">
                                    <div class="form_grp">
                                        <div class="group_item">
                                            <label class="input_label">Account SID</label>
                                            <div class="field_wpr" :class="{ 'has-error': errors.twilio_sid }">
                                                <Field autocomplete="off" type="text" name="twilio_sid" v-model="twilioForm.twilio_sid" placeholder="Account SID" />
                                            </div>
                                            <ErrorMessage name="twilio_sid" class="text-danger" />
                                        </div>
                                    </div>
                                    <div class="form_grp">
                                        <div class="group_item">
                                            <label class="input_label">Auth Token</label>
                                            <div class="field_wpr" :class="{ 'has-error': errors.twilio_token }">
                                                <Field autocomplete="off" type="text" name="twilio_token" v-model="twilioForm.twilio_token" placeholder="Auth Token" />
                                            </div>
                                            <ErrorMessage name="twilio_token" class="text-danger" />
                                        </div>
                                    </div>
                                    <div class="form_grp">
                                        <div class="group_item">
                                            <label class="input_label">Phone Number</label>
                                            <div class="field_wpr" :class="{ 'has-error': errors.twilio_phone }">
                                                <Field autocomplete="off" type="text" name="twilio_phone" v-model="twilioForm.twilio_phone" placeholder="Twilio Number" />
                                            </div>
                                            <ErrorMessage name="twilio_phone" class="text-danger" />
                                        </div>
                                    </div>
                                    <label for="connection_status" class="switch_option capsule_btn">
                                        <h5>Connection Status</h5>
                                        <input type="checkbox" id="connection_status" :true-value="1" :false-value="0" v-model="twilioForm.connection_status" @change="handleConnectionStatus" hidden>
                                        <div><span></span></div>
                                    </label>
                                </div>
                                <div class="action_wpr fixed_footer">
                                    <button :disabled="twilioSaveLoader" class="btn save_btn"><i class="fa fa-spin fa-spinner" v-if="twilioSaveLoader"></i> {{ twilioSaveLoader ? 'Saving' : 'Save' }}</button>
                                </div>
                            </Form>
                        </div>
                    </div>
                </div>
                <div class="modal_footer">
                    <button class="btn cancel_btn" @click="closeModal()">Cancel</button>
                </div>
            </Form>
        </div>
    </div>
</template>

<script>
    import { Field, Form, ErrorMessage } from 'vee-validate'
    import { mapState, mapActions } from 'vuex'

    import axios from '@/services/axios'
    import Helper from '@/utils/Helper'
    import Toastr from '@/utils/Toastr'
    import Swal from 'sweetalert2'

    export default {
        name: 'User Admin Setting',

        data () {
            return {
                addNewUser: false,
                permission: false,
                showDetails: false,
                form: {
                    id: 0,
                    user_type: 'admin',
                    full_name: '',
                    email: '',
                    companyno: '',
                    country: '',
                    city: '',
                    state: '',
                    zipcode: '',
                    has_company_access: 0,
                    contact_id: 0,
                    locations: []
                },
                types: [
                    {
                        title: 'Admin (Create/Edit/Delete privileges)',
                        value: 'admin',
                    },
                    {
                        title: 'Staff (Edit and View privileges)',
                        value: 'staff',
                    },
                    {
                        title: 'View Only (View only privileges)',
                        value: 'view only',
                    },
                ],
                userPermission: [],
                selectedUserDropdown: 0,
                billingApi: process.env.VUE_APP_BILLING_API_URL,
                tab: 1,
                twilioForm: {
                    twilio_sid: '',
                    twilio_token: '',
                    twilio_phone: '',
                    connection_status: 0,
                },
                twilioSaveLoader: false,
            };
        },

        props: {
            modelValue: Boolean,
        },

        emits: ['update:modelValue'],

        components: {
            Field,
            Form,
            ErrorMessage,
        },

        watch: {
            'form.user_type': function (type) {
                const vm = this;
                let permission;

                if (type == 'admin') {
                    permission = '1';
                } else if (type == 'staff') {
                    permission = '2';
                } else if (type == 'view only') {
                    permission = '3';
                }

                if (permission) {
                    vm.permissions.forEach((perm, key) => {
                        if (perm.name == 'Integrations') {
                            vm.userPermission[perm.id] = {};

                            perm.types.forEach((type) => {
                                vm.userPermission[perm.id][type.id] = permission == 3 ? false : true;
                            });
                        } else {
                            vm.userPermission[perm.id] = {};
                            vm.userPermission[perm.id][permission] = true;
                        }
                    });
                }
            },

            permissions: function (n) {
                const vm = this;

                vm.permissions.forEach((permission, key) => {
                    vm.userPermission[permission.id] = {};
                });
            },

            'form.contact_id' (contactId) {
                const vm = this;

                if (contactId) {
                    const contact = vm.contacts.find(({ id }) => id == contactId);

                    if (contact) {
                        vm.form.full_name = contact.name;
                        vm.form.email     = contact.email;
                        vm.form.companyno = contact.number;
                        vm.form.country   = contact.country;
                        vm.form.city      = contact.city;
                        vm.form.state     = contact.state;
                        vm.form.zipcode   = contact.zipcode;
                        vm.form.locations = [];
                    }
                }
            },

            modelValue (val) {
                const vm = this;

                vm.tab = 1;

                if (val) {
                    vm.resetForm();
                    document.body.classList.add('modal-open');
                } else {
                    document.body.classList.remove('modal-open');
                }
            }
        },

        computed: mapState({
            user: state => state.authModule.user,
            organizationUsers: state => state.authModule.organizationUsers,
            countries: state => state.commonModule.countries,
            contacts: state => state.contactModule.allContacts,
            permissions: state => state.authModule.permissions,
            userSaveLoader: state => state.authModule.userSaveLoader,
            organizationUserLoader: state => state.authModule.organizationUserLoader,
            membership: state => state.authModule.membership,
            plansAndAddons: state => state.billingModule.plans,
            subscription: state => state.billingModule.subscription,
            organizationLocations: state => state.authModule.organizationLocations,
            locationLoader: state => state.authModule.locationLoader,
        }),

        mounted () {
            const vm = this;

            vm.getOrganizationUsers();
            vm.getOrganizationLocations();
        },

        methods: {
            ...mapActions({
                refreshAuth: 'authModule/refreshAuth',
                getAllContacts: 'contactModule/getAllContacts',
                getPermissions: 'authModule/getPermissions',
                getUserPermissions: 'authModule/getUserPermissions',
                getOrganizationUsers: 'authModule/getOrganizationUsers',
                getOrganizationLocations: 'authModule/getOrganizationLocations',
                saveUser: 'authModule/saveUser',
                deleteUser: 'authModule/deleteUser',
                resendLoginCredentials: 'authModule/resendLoginCredentials',
                accessAccount: 'authModule/accessAccount',
                storeTwilioCredentials: 'authModule/storeTwilioCredentials',
                updateTwilioConnection: 'authModule/updateTwilioConnection',
            }),

            closeModal () {
                const vm = this;

                vm.addNewUser  = false;
                vm.permission  = false;
                vm.showDetails = false;
                vm.$emit('update:modelValue', false);
            },

            resetValidation () {
                const vm = this;
                const form = vm.$refs['user-form'];
                vm.$refs['user-type-ms'].clear();
                vm.$refs['contact-ms'].clear();

                if (form) {
                    form.resetForm();
                }
            },

            searchContact (search) {
                const vm = this;

                vm.getAllContacts({ search });
            },

            handleSubmit (params, { setFieldError }) {
                const vm = this;

                if (params.step == 2) {
                    vm.form.permissions   = vm.userPermission;
                    vm.form.setFieldError = setFieldError;

                    vm.saveUser(vm.form).then((result) => {
                        if (result) {
                            vm.permission   = false;
                            vm.addNewUser   = false;
                            vm.showDetails  = false;
                            vm.tab          = 1;

                            vm.refreshAuth();
                            vm.getOrganizationUsers();
                        } else {
                            vm.permission   = false;
                            vm.addNewUser   = true;
                            vm.showDetails  = true;
                            vm.tab          = 1;
                        }
                    });

                } else {
                    vm.addNewUser = true;
                    vm.permission = true;
                    vm.tab        = 2;
                }
            },

            toggleUserDropdown (id) {
                const vm = this;

                vm.selectedUserDropdown = id;
            },

            closeUserDropdown () {
                const vm = this;

                vm.selectedUserDropdown = 0;
            },

            editUser (user) {
                const vm = this;

                vm.form.id                  = user.id;
                vm.form.user_type           = user.user_type;
                vm.form.full_name           = user.full_name;
                vm.form.email               = user.email;
                vm.form.companyno           = user.companyno;
                vm.form.country             = user.country;
                vm.form.city                = user.city;
                vm.form.state               = user.state;
                vm.form.zipcode             = user.zipcode;
                vm.form.has_company_access  = user.has_company_access;
                vm.form.locations           = user.locations ? user.locations : [];

                vm.twilioForm.user_id       = user.id;
                vm.twilioForm.twilio_sid    = user.twilio_sid;
                vm.twilioForm.twilio_token  = user.twilio_token;
                vm.twilioForm.twilio_phone  = user.twilio_phone;

                vm.addNewUser     = true;
                vm.showDetails    = true;

                vm.getUserPermissions(user.id).then((resp) => {
                    vm.userPermission = [];

                    vm.permissions.forEach((permission, key) => {
                        vm.userPermission[permission.id] = {};
                    });

                    resp.data.forEach((perm, key) => {
                        if (!vm.userPermission[perm.permission_id]) {
                            vm.userPermission[perm.permission_id] = {};
                        }
                        vm.userPermission[perm.permission_id][perm.permission_type] = true;
                    });
                }).catch((err) => {
                    console.log(err);
                });
            },

            handleDeleteUser (user) {
                const vm = this;
                const options = Helper.swalConfirmOptions('Are you sure?', `You want to delete this user, you will not be able to recover this user.`);
                options.preConfirm = () => {
                                                return vm.deleteUser(user.id).then((result) => {
                                                    if (result) {
                                                        vm.refreshAuth();
                                                        vm.getOrganizationUsers();
                                                    }
                                                });
                                            }

                Swal.fire(options);
            },

            handleResendLoginCredentials (user) {
                const vm = this;
                const options = Helper.swalConfirmOptions('Are you sure?', `You want to resend login credentials to ${user.full_name}.`);
                options.preConfirm = () => { return vm.resendLoginCredentials(user.id); }

                Swal.fire(options);
            },

            handleAddUser () {
                const vm = this;

                if (!vm.membership.team_members) {
                    vm.addNewUser = true;
                    vm.showDetails = false;
                    vm.form = { locations: [] };
                    vm.form.user_type = '';
                    vm.form.contact_id = '';

                    setTimeout(function () {
                        vm.resetValidation();
                    }, 10);
                } else {
                    if (vm.user.stripe_id) {
                        const options = Helper.swalConfirmOptions('Are you sure?', `You have exceeded your limit. If you want to add more user, you have to add another additional user plan.`, 'PROCEED', false);

                        Swal.fire(options).then((result) => {
                            if (result.isConfirmed ) {
                                vm.billingConfirmation();
                            }
                        });
                    } else {
                        const options = Helper.swalConfirmOptions('Limit exceeded', 'You have reached your users limit for this plan. Please contact support or open a chat to upgrade your account and unlock more features.', 'Contact Support', false);

                        Swal.fire(options).then((result) => {
                            if (result.isConfirmed) {
                                window.location = 'mailto:support@onboardme.io?subject=Upgrade Plan';
                            }
                        });
                    }
                }
            },

            billingConfirmation () {
                const vm = this;
                const addonPlan = vm.plansAndAddons.addons.month.filter((plan) => plan.nickname == 'user')[0];
                const options = Helper.swalConfirmOptions('Thank You!', `$${Math.round((parseFloat(addonPlan.amount) + Number.EPSILON) * 100) / 100} will be charged now and will be added to your next billing. Would you like to proceed?`, 'PROCEED');
                options.preConfirm = function () {
                                          return new Promise(function(resolve, reject) {
                                              axios.post(`${vm.billingApi}/stripe/subscription/increment-quantity`, { plan: addonPlan.price_id }, {
                                                  headers: {
                                                      Accept: 'application/json',
                                                      Authorization: `Bearer ${vm.user.access_token}`,
                                                  },
                                              }).then((resp) => {
                                                  vm.refreshAuth();

                                                  Toastr.success('One additional user add-on has been added to your subscription successfully!');
                                                  resolve(true);
                                              }).catch((err) => {
                                                  Toastr.error(err.response.data.error);
                                                  resolve(true);
                                              });
                                          });
                                      }

                Swal.fire(options);
            },

            resetForm () {
                const vm = this;

                if (!vm.contacts.length) {
                    vm.getAllContacts({});
                }

                if (!vm.permissions.length) {
                    vm.getPermissions();
                }

                if (!vm.organizationUsers.length) {
                    vm.getOrganizationUsers();
                }

                vm.form = {
                    id: 0,
                    user_type: 'admin',
                    full_name: '',
                    email: '',
                    companyno: '',
                    country: '',
                    city: '',
                    state: '',
                    zipcode: '',
                    has_company_access: 0,
                    contact_id: 0,
                    locations: []
                };
            },

            handleTwilioFormSubmit () {
                const vm = this;
                vm.twilioSaveLoader = true;

                vm.storeTwilioCredentials(vm.twilioForm).then((result) => {
                    if (result) {
                        vm.getOrganizationUsers();
                    }

                    vm.twilioSaveLoader = false;
                });
            },

            handleConnectionStatus () {
                const vm = this;

                const params = {
                    connection_status: vm.twilioForm.connection_status,
                    twilio_sid: vm.twilioForm.twilio_sid,
                    twilio_token: vm.twilioForm.twilio_token,
                    twilio_phone: vm.twilioForm.twilio_phone,
                    user_id: vm.twilioForm.user_id,
                };

                vm.twilioSaveLoader = true;

                vm.updateTwilioConnection(params).then((result) => {
                    if (result) {
                        vm.getOrganizationUsers();
                    }

                    vm.twilioSaveLoader = false;
                });
            },
        },
    }
</script>

<style scoped>
    .mh-640 {
        min-height: 640px;
    }
    .setting-form{
        height: auto;
    }
    .permission_list{
        padding: 20px;
        margin: 30px 0;
        background: #fff;
        border-radius: 8px;
    }
    .permission_list h5 {
        font-size: 15px;
        line-height: 20px;
        font-weight: 400;
        color: #121525;
        padding: 10px 0;
    }
    .permission_list label.checkbox p{
        font-size: 12px;
        line-height: 16px;
        color: #5a5a5a;
    }

    .permission_list ul {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -5px
    }

    .permission_list ul li {
        flex: 1 0 33.333%;
        padding: 5px;
        display: flex;
        align-items: center;
        font-size: 13px;
        line-height: 16px;
    }

    .dropdown .drp_wrapper {
        display: none;
    }

    .dropdown.active .drp_wrapper {
        display: block;
    }
    .storage {
        padding: 5px 15px;
        background: #fff;
        border: 1px solid #f5f5f5;
        border-radius: 3px;
        position: relative;
        overflow: hidden;
    }

    .storage h4 {
        font-size: 10px;
        line-height: 12px;
        color: #5a5a5a;
        font-weight: 500;
        position: relative;
        z-index: 1;
    }

    .storage span {
        background: rgba(47, 126, 237, 0.1);
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        z-index: 0;
    }

    .locations-list {
        background: #fff;
        border-radius: 8px;
        padding: 20px;
    }

    .locations-list .switch_option {
        padding: 5px 0px;
        border-bottom: 0px;
    }

    h4.permission-title {
        font-size: 16px;
        line-height: 24px;
        font-weight: 500;
        margin-bottom: 15px;
    }

    @media(max-width: 768px){
        .storage{
            margin-top: 10px;
        }
        .permission_list ul li{
            flex: 0 0 100%;
        }
    }
</style>
